import React from "react";
import Dot from "../components/backgrounds/Dot";
import Footer from "../components/home/Footer";
import { Link } from "react-router-dom";
import Role from "../components/arrays/Role";
const Jobs = () => {
  return (
    <>
      <div className="w-full min-h-screen flex bg-white pt-[9vh] p-6 flex-col relative gap-2">
        <Dot />
        <h1
          data-aos="fade-down"
          className="w-full text-center text-3xl font-bold text-primary mt-20"
        >
          Join Our Team
        </h1>
        <h2
          data-aos="fade-right"
          className="w-full text-left text-lg font-bold text-secondary mt-2"
        >
          Available Positions:
        </h2>
        <div className="flex flex-col gap-3 text-primary mt-4 text-sm">
          {Role.map((item) => {
            return (
              <Link
                key={item.title}
                to={`/job-offers/${item.title}`}
                className="flex items-center gap-2"
              >
                <i className="fa-solid fa-caret-right text-secondary"></i>
                <span>{item.title}</span>
              </Link>
            );
          })}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Jobs;
