import React from "react";
import Footer from "../components/home/Footer";
import Section1 from "../components/home/Section1";
import Section2 from "../components/home/Section2";
import Section3 from "../components/home/Section3";
import Section4 from "../components/home/Section4";

const Home = () => {
  return (
    <div className="w-full flex flex-col bg-white overflow-hidden">
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Footer />
    </div>
  );
};

export default Home;
