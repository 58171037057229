import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import api from "../apis/api";

const SingleApplication = () => {
  const { id } = useParams();
  const [application, setApplication] = useState(null);
  const [loading, setLoading] = useState(false);

  const getDetails = async () => {
    setLoading(true);
    try {
      const res = await api.get(`/applications/${id}`);
      setApplication(res.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getDetails();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="w-full min-h-screen flex bg-white pt-[9vh] p-6 flex-col relative gap-2">
      <h1
        data-aos="fade-down"
        className="w-full text-left text-3xl font-bold text-primary mt-20"
      >
        Application
      </h1>
      {loading && (
        <span className="animate-pulse text-primary text-xs text-center mt-10 w-full">
          Loading applications
        </span>
      )}
      {application && (
        <div className="flex flex-col gap-4 text-xs text-primary">
          <h2 className="text-lg font-bold text-primary">
            Personal Information:
          </h2>
          <p className="capitalize">
            Name: {application.first_name} {application.last_name}
          </p>
          <p>Email: {application.email}</p>
          <p>Phone Number: {application.phone_number}</p>
          <p>Home Address: {application.home_address}</p>

          <h2 className="text-lg font-bold text-primary mt-4">
            Identification Information:
          </h2>
          <p>Social Security Number: {application.social_security_number}</p>

          <h2 className="text-lg font-bold text-primary mt-4">Documents:</h2>
          <p>
            CV:{" "}
            <a
              className="text-primary bg-secondary px-2 py-1 rounded hover:bg-primary hover:text-secondary transition-all ease-in-out text-xs font-semibold"
              href={application.cv}
              download
            >
              Download CV
            </a>
          </p>
          {application.cover_letter && (
            <p>
              Cover Letter:{" "}
              <a
                className="text-primary bg-secondary px-2 py-1 rounded hover:bg-primary hover:text-secondary transition-all ease-in-out text-xs font-semibold"
                href={application.cover_letter}
                download
              >
                Download Cover Letter
              </a>
            </p>
          )}
          <p>
            Driver's License Front:{" "}
            <a
              className="text-primary bg-secondary px-2 py-1 rounded hover:bg-primary hover:text-secondary transition-all ease-in-out text-xs font-semibold"
              href={application.drivers_license_front}
              download
            >
              Download Driver's License Front
            </a>
          </p>
          <p>
            Driver's License Back:{" "}
            <a
              className="text-primary bg-secondary px-2 py-1 rounded hover:bg-primary hover:text-secondary transition-all ease-in-out text-xs font-semibold"
              href={application.drivers_license_back}
              download
            >
              Download Driver's License Back
            </a>
          </p>
        </div>
      )}
    </div>
  );
};

export default SingleApplication;
