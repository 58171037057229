import React from "react";

const items = [
  {
    name: "Experienced Professionals",
    body: "Our team consists of seasoned professionals with a proven track record of delivering successful PR and advertising campaigns.",
  },
  {
    name: "Tailored Solutions",
    body: " We understand that every business is unique, which is why we customize our services to meet your specific goals and objectives.",
  },
  {
    name: "Results-Driven Approach",
    body: " We are dedicated to delivering measurable results that drive growth and success for your business.",
  },
  {
    name: "Client-Centric Focus",
    body: "Your success is our priority, and we are committed to providing unparalleled customer service and support every step of the way.",
  },
];
const Section3 = () => {
  return (
    <div className="w-full max-lg:h-fit h-screen flex bg-white pt-[9vh] p-6 flex-col items-center justify-center gap-10">
      <h1
        data-aos="fade-left"
        className="w-full text-center text-3xl max-lg:text-2xl font-bold text-primary"
      >
        Why Choose Upthrive?
      </h1>
      <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5">
        {items.map((item) => {
          return (
            <div
              data-aos="zoom-in-up"
              className="w-full p-5 flex flex-col gap-5 border shadow rounded shadow-secondary"
            >
              <h3 className="w-full text-right text-secondary text-lg font-medium border-b border-secondary/50 py-2">
                {item.name}
              </h3>
              <p className="text-left text-sm text-primary">{item.body}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Section3;
