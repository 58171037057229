import React from "react";

const Section4 = () => {
  return (
    <div className="w-full max-lg:h-fit h-screen flex bg-white pt-[9vh] p-6 flex-col items-center justify-center gap-10">
      <h1
        data-aos="fade-down"
        className="w-full text-center text-3xl max-lg:text-2xl font-bold text-primary"
      >
        Contact Us
      </h1>
      <p
        data-aos="zoom-in-down"
        className="w-full text-left text-secondary text-sm"
      >
        Ready to take your brand to the next level? Contact us today to learn
        more about our services and how we can help you succeed.
      </p>
      <div className="w-full flex flex-col justify-center gap-5 text-primary">
        <div data-aos="fade-up" className="w-full flex items-center gap-2">
          <i className="fa-solid fa-phone text-xl"></i>
          <span className="text-sm">+1 (405) 369 6126</span>
        </div>
        <div data-aos="fade-up" className="w-full flex items-center gap-2">
          <i className="fa-solid fa-location-dot text-xl"></i>
          <span className="text-sm">1827 Adonis Ave. Henderson NV 89074</span>
        </div>
        <div data-aos="fade-up" className="w-full flex items-center gap-2">
          <i className="fa-solid fa-envelope text-xl"></i>
          <span className="text-sm">support@uprac.biz</span>
        </div>
      </div>
    </div>
  );
};

export default Section4;
