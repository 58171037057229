import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../logos/Uprac.png";

const item = [
  { name: "Home", link: "/" },
  { name: "Terms & Condition", link: "/terms-and-conditions" },
  { name: "Privacy Policy", link: "/privacy-policy" },
];
const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navbarRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        setIsOpen(false); // Close the menu if clicked outside the navbar
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  return (
    <nav ref={navbarRef} className="w-full fixed bg-white z-40">
      <div
        className="w-full h-[9vh] px-5
       flex items-center justify-between relative"
      >
        <Link to={`/`}>
          <img src={Logo} alt="" className="w-16" />
        </Link>
        <ul className="flex gap-4 text-sm font-semibold max-lg:hidden">
          {item.map((item) => {
            return (
              <Link to={item.link} className="text-primary">
                {item.name}
              </Link>
            );
          })}
        </ul>
        <div className="flex gap-2 items-center">
          <Link
            to={`/job-offers`}
            className="text-primary bg-secondary px-4 py-2 rounded hover:bg-primary hover:text-secondary transition-all ease-in-out text-xs font-semibold"
          >
            Job offers
          </Link>
          <div
            onClick={toggleMenu}
            className={`w-6 h-6 max-lg:flex flex-col justify-between hidden z-50 transition-all ease-in-out items-start `}
          >
            <span
              className={`w-full h-0.5 bg-primary transition-all ease-in-out duration-1000 ${
                isOpen ? "rotate-45 translate-y-2.5" : ""
              }`}
            ></span>
            <span
              className={`w-full h-0.5 bg-primary transition-all ease-in-out duration-1000 ${
                isOpen ? "opacity-0" : ""
              }`}
            ></span>
            <span
              className={`w-full h-0.5 bg-primary transition-all ease-in-out duration-1000 ${
                isOpen ? "-rotate-45 -translate-y-3" : ""
              }`}
            ></span>
          </div>
        </div>
        <div
          className={`w-full absolute flex-col transition-all ease-in-out max-lg:flex hidden ${
            isOpen ? "h-[18vh]" : "h-0"
          } bg-white left-0 top-[9vh]`}
        >
          {item.map((item) => {
            return (
              <Link
                to={item.link}
                onClick={() => setIsOpen(false)}
                className={`w-full px-2 flex items-center transition-all ease-in-out ${
                  isOpen ? "h-[6vh] border-b border-secondary" : "h-0"
                }`}
              >
                <span
                  className={`text-sm text-primary transition-all ease-in-out ${
                    isOpen ? "" : "opacity-0"
                  }`}
                >
                  {item.name}
                </span>
              </Link>
            );
          })}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
