import React, { useEffect, useState } from "react";
import api from "../apis/api";
import { Link } from "react-router-dom";

const Applications = () => {
  const [applications, setApplications] = useState([]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const getDetails = async () => {
    setLoading(true);
    try {
      const res = await api.get("/applications");
      const res2 = await api.get("/application/count");
      setApplications(res.data);
      setCount(parseInt(res2.data));
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getDetails();
    // eslint-disable-next-line
  }, []);

  if (loading === true) {
    return (
      <div className="w-full min-h-screen flex bg-white pt-[9vh] p-6 flex-col relative gap-2">
        <h1
          data-aos="fade-down"
          className="w-full text-left text-3xl font-bold text-primary mt-20"
        >
          All applications
        </h1>
        <span className="animate-pulse text-primary text-xs text-center mt-10 w-full">Loading applications</span>
      </div>
    );
  }

  return (
    <div className="w-full min-h-screen flex bg-white pt-[9vh] p-6 flex-col relative gap-2">
      <h1
        data-aos="fade-down"
        className="w-full text-left text-3xl font-bold text-primary mt-20"
      >
        All applications
      </h1>
      <div className="p-3 bg-[whitesmoke] shadow w-fit mt-2 flex items-center gap-2 cursor-pointer">
        <i class="fa-solid fa-file text-primary text-2xl"></i>
        <div className="flex flex-col items-end">
          <span className="text-xs text-primary">Total applications</span>
          <span className="text-sm text-primary font-semibold">{count}</span>
        </div>
      </div>
      <table className="text-primary text-sm table-auto">
        <thead>
          <tr>
            <th className="border-b border-primary text-start p-2">Name</th>
            <th className="border-b border-primary text-start p-2">Role</th>
            <th className="border-b border-primary text-start p-2">Link</th>
          </tr>
        </thead>
        <tbody>
          {applications.map((item) => {
            return (
              <tr key={item.id}>
                <td className="p-2 capitalize">{item.first_name}</td>
                <td className="p-2">{item.role}</td>
                <td className="p-2">
                  <Link
                    to={`/restricted-access/admin/applications/${item.id}`}
                    className="text-primary bg-secondary px-2 py-1 rounded hover:bg-primary hover:text-secondary transition-all ease-in-out text-xs font-semibold"
                  >
                    View
                  </Link>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Applications;
