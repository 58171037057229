import React from "react";
import Dot from "../backgrounds/Dot";
import Image from "../images/section1.png";

const Section1 = () => {
  return (
    <div className="w-full h-screen flex bg-white pt-[9vh] px-6 max-lg:flex-col relative">
      <Dot />
      <div className="flex-col flex gap-2 w-full justify-center max-lg:justify-end h-full">
        <h1
          data-aos="fade-right"
          className="w-full text-left text-3xl font-bold text-primary"
        >
          Welcome to Upthrive
        </h1>
        <h1
          data-aos="fade-right"
          className="w-full text-left text-3xl font-bold text-secondary"
        >
          Where Your Success is Our Priority
        </h1>
        <p data-aos="fade-down" className="text-xs text-primary">
          At Upthrive, we specialize in providing comprehensive public relations
          and advertising solutions tailored to your unique business needs. With
          years of experience and a team of dedicated professionals, we are
          committed to helping your brand thrive in today's competitive market.
        </p>
      </div>
      <div
        data-aos="fade-left"
        className="flex-col flex gap-2 w-full justify-center items-center h-full"
      >
        <img src={Image} alt="" className="w-44 md:w-48 lg:w-64" />
      </div>
    </div>
  );
};

export default Section1;
