import React from "react";

const items = [
  {
    name: "Public Relations",
    body: "We excel in building positive relationships between your brand and the public, enhancing your reputation and credibility.",
  },
  {
    name: "Advertising",
    body: "Our creative advertising strategies are designed to elevate your brand's visibility and drive results.",
  },
  {
    name: "Brand Management",
    body: "We craft compelling brand stories and strategies to establish a strong and memorable brand identity.",
  },
  {
    name: "Marketing Campaigns",
    body: "From traditional to digital marketing campaigns, we create targeted strategies to reach your audience and achieve your business goals.",
  },
  {
    name: "Digital Advertising",
    body: "Harness the power of digital platforms with our expertly crafted online advertising campaigns.",
  },
];

const Section2 = () => {
  return (
    <div className="w-full max-lg:h-fit h-screen flex bg-white pt-[9vh] p-6 flex-col items-center justify-center gap-10">
      <h1
        data-aos="fade-right"
        className="w-full text-center text-3xl max-lg:text-2xl font-bold text-primary"
      >
        Our Services
      </h1>
      <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
        {items.map((item) => {
          return (
            <div
              data-aos="fade-up"
              className="w-full p-5 flex flex-col gap-4"
            >
              <h3 className="w-full text-center text-secondary text-lg font-medium">
                {item.name}
              </h3>
              <p className="text-center text-sm text-primary font-medium">
                {item.body}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Section2;
