import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import api from "../apis/api";
import Role from "../components/arrays/Role";
import Footer from "../components/home/Footer";
import { toast } from "react-toastify";

const Apply = () => {
  const { role } = useParams();
  const navigate = useNavigate();
  const [selectedRole, setSelectedRole] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [homeAddress, setHomeAddress] = useState("");
  const [cv, setCv] = useState(null);
  const [coverLetter, setCoverLetter] = useState(null);
  const [socialSecurityNumber, setSocialSecurityNumber] = useState("");
  const [driversLicenseFront, setDriversLicenseFront] = useState(null);
  const [driversLicenseBack, setDriversLicenseBack] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    localStorage.setItem("Role: ", role);
    const foundRole = Role.find((r) => r.title === role);
    setSelectedRole(foundRole);
  }, [role]);

  const handleFileChange = (e, setter) => {
    setter(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("first_name", firstName);
      formData.append("last_name", lastName);
      formData.append("email", email);
      formData.append("phone_number", phoneNumber);
      formData.append("home_address", homeAddress);
      formData.append("cv", cv);
      formData.append("cover_letter", coverLetter);
      formData.append("social_security_number", socialSecurityNumber);
      formData.append("drivers_license_front", driversLicenseFront);
      formData.append("drivers_license_back", driversLicenseBack);
      formData.append("role", role); // Assuming role is passed as a prop

      await api.post(`/application`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setLoading(false);
      toast.success("Application submitted successfully");
      navigate("/");
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.message);
      console.error("Error submitting application", error);
    }
  };

  return (
    <>
      <div className="w-full min-h-screen flex bg-white pt-[9vh] p-6 flex-col relative gap-2">
        {selectedRole && (
          <>
            <h1
              data-aos="fade-down"
              className="w-full text-left text-lg font-medium text-primary mt-20"
            >
              Apply for a role as a {role}
            </h1>
            <div className="text-left flex flex-col gap-2">
              <h2 className="font-medium text-secondary">Responsibilities:</h2>
              <ul className="leading-6 text-xs text-primary list-inside list-disc">
                {selectedRole.responsibilities.map((responsibility, index) => (
                  <li key={index}>{responsibility}</li>
                ))}
              </ul>
              <h2 className="font-medium text-secondary">Requirements:</h2>
              <ul className="leading-6 text-xs text-primary list-inside list-disc">
                {selectedRole.requirements.map((requirement, index) => (
                  <li key={index}>{requirement}</li>
                ))}
              </ul>
            </div>
          </>
        )}
        <form
          onSubmit={handleSubmit}
          className="w-full md:w-2/3 lg:w-1/3 mt-5 gap-2 flex flex-col"
        >
          <h2 className="font-medium text-secondary">Application form:</h2>
          <h3 className="text-secondary text-xs mt-3">Personal Information</h3>
          <input
            type="text"
            className="w-full text-sm lg:text-xs text-primary border-2 outline-none p-1.5 rounded focus-within:border-primary"
            placeholder="First name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
          <input
            type="text"
            className="w-full text-sm lg:text-xs text-primary border-2 outline-none p-1.5 rounded focus-within:border-primary"
            placeholder="Last name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
          <input
            type="email"
            className="w-full text-sm lg:text-xs text-primary border-2 outline-none p-1.5 rounded focus-within:border-primary"
            placeholder="Email address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="number"
            className="w-full text-sm lg:text-xs text-primary border-2 outline-none p-1.5 rounded focus-within:border-primary"
            placeholder="Phone number"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
          <input
            type="text"
            className="w-full text-sm lg:text-xs text-primary border-2 outline-none p-1.5 rounded focus-within:border-primary"
            placeholder="Home address"
            value={homeAddress}
            onChange={(e) => setHomeAddress(e.target.value)}
          />
          <h3 className="text-secondary text-xs mt-3">Supporting Documents</h3>
          <div className="w-full flex flex-col gap-1">
            <span className="w-full text-primary text-xs">CV:</span>
            <input
              type="file"
              className="w-full text-sm lg:text-xs text-primary border-2 outline-none p-1.5 rounded focus-within:border-primary file:bg-secondary file:border-0 file:text-white"
              onChange={(e) => handleFileChange(e, setCv)}
              accept=".pdf"
            />
          </div>
          <div className="w-full flex flex-col gap-1">
            <span className="w-full text-primary text-xs">Cover letter:</span>
            <input
              type="file"
              className="w-full text-sm lg:text-xs text-primary border-2 outline-none p-1.5 rounded focus-within:border-primary file:bg-secondary file:border-0 file:text-white"
              onChange={(e) => handleFileChange(e, setCoverLetter)}
              accept=".pdf"
            />
          </div>
          <h3 className="text-secondary text-xs mt-3">
            Identification Information
          </h3>
          <input
            type="number"
            className="w-full text-sm lg:text-xs text-primary border-2 outline-none p-1.5 rounded focus-within:border-primary"
            placeholder="Social security number"
            value={socialSecurityNumber}
            onChange={(e) => setSocialSecurityNumber(e.target.value)}
          />
          <div className="w-full flex flex-col gap-1">
            <span className="w-full text-primary text-xs">
              Drivers license (front)
            </span>
            <input
              type="file"
              className="w-full text-sm lg:text-xs text-primary border-2 outline-none p-1.5 rounded focus-within:border-primary file:bg-secondary file:border-0 file:text-white"
              onChange={(e) => handleFileChange(e, setDriversLicenseFront)}
              accept="image/*"
            />
          </div>
          <div className="w-full flex flex-col gap-1">
            <span className="w-full text-primary text-xs">
              Drivers license (back)
            </span>
            <input
              type="file"
              className="w-full text-sm lg:text-xs text-primary border-2 outline-none p-1.5 rounded focus-within:border-primary file:bg-secondary file:border-0 file:text-white"
              onChange={(e) => handleFileChange(e, setDriversLicenseBack)}
              accept="image/*"
            />
          </div>
          {loading ? (
            <button
              disabled
              className="text-primary bg-secondary px-4 py-1 rounded text-sm mt-2"
            >
              Loading
            </button>
          ) : (
            <button
              type="submit"
              className="text-primary bg-secondary px-4 py-1 rounded hover:bg-primary hover:text-secondary transition-all ease-in-out text-sm mt-2"
            >
              Submit
            </button>
          )}
        </form>
      </div>
      <Footer />
    </>
  );
};

export default Apply;
