import React from "react";
import Dot from "../components/backgrounds/Dot";
import Footer from "../components/home/Footer";

const Terms = () => {
  return (
    <>
      <div className="w-full min-h-screen flex bg-white pt-[9vh] p-6 flex-col relative gap-2">
        <Dot />
        <h1
          data-aos="fade-down"
          className="w-full text-center text-3xl font-bold text-primary mt-20"
        >
          Terms and Conditions
        </h1>
        <h2
          data-aos="fade-right"
          className="w-full text-left text-lg font-bold text-secondary mt-2"
        >
          Acceptance of Terms
        </h2>
        <p className="text-xs text-primary">
          By accessing or using the services provided by Upthrive Public
          Relations and Advertising Company ("Upthrive"), you agree to be bound
          by these Terms and Conditions. If you do not agree to these terms,
          please refrain from using our services.
        </p>
        <h2
          data-aos="fade-right"
          className="w-full text-left text-lg font-bold text-secondary mt-2"
        >
          Services
        </h2>
        <p className="text-xs text-primary">
          Upthrive offers public relations and advertising services. The details
          of these services, including pricing and deliverables, will be
          outlined in a separate agreement between Upthrive and the client.
        </p>
        <h2
          data-aos="fade-right"
          className="w-full text-left text-lg font-bold text-secondary mt-2"
        >
          Intellectual Property
        </h2>
        <p className="text-xs text-primary">
          All content and materials provided by Upthrive, including but not
          limited to text, graphics, logos, and images, are the property of
          Upthrive and are protected by intellectual property laws. Clients are
          granted a limited, non-exclusive, non-transferable license to use
          these materials solely for the purpose of receiving Upthrive's
          services.
        </p>
        <h2
          data-aos="fade-right"
          className="w-full text-left text-lg font-bold text-secondary mt-2"
        >
          Confidentiality
        </h2>
        <p className="text-xs text-primary">
          Upthrive agrees to keep confidential all non-public information
          provided by the client. Similarly, clients agree not to disclose any
          confidential information about Upthrive.
        </p>
        <h2
          data-aos="fade-right"
          className="w-full text-left text-lg font-bold text-secondary mt-2"
        >
          Payment and Billing
        </h2>
        <p className="text-xs text-primary">
          Payment terms and billing details will be specified in the agreement
          between Upthrive and the client. Failure to pay invoices within the
          specified time frame may result in the suspension of services.
        </p>
        <h2
          data-aos="fade-right"
          className="w-full text-left text-lg font-bold text-secondary mt-2"
        >
          Termination
        </h2>
        <p className="text-xs text-primary">
          Either party may terminate the services provided by Upthrive with
          written notice. In the event of termination, the client is responsible
          for all outstanding fees up to the termination date.
        </p>
        <h2
          data-aos="fade-right"
          className="w-full text-left text-lg font-bold text-secondary mt-2"
        >
          Limitation of Liability
        </h2>
        <p className="text-xs text-primary">
          Upthrive shall not be liable for any indirect, incidental, special, or
          consequential damages arising out of or in connection with the
          services provided.
        </p>
        <h2
          data-aos="fade-right"
          className="w-full text-left text-lg font-bold text-secondary mt-2"
        >
          Changes to Terms
        </h2>
        <p className="text-xs text-primary">
          Upthrive reserves the right to modify these Terms and Conditions at
          any time. Clients will be notified of any changes, and continued use
          of our services after such modifications constitutes acceptance of the
          updated terms.
        </p>
        <h2
          data-aos="fade-right"
          className="w-full text-left text-lg font-bold text-secondary mt-2"
        >
          Contact Information
        </h2>
        <p className="text-xs text-primary">
          For any questions regarding these Terms and Conditions, please contact
          Upthrive at <span className="text-secondary">support@uprac.biz</span>.
        </p>
      </div>
      <Footer />
    </>
  );
};

export default Terms;
