import React from "react";
import Dot from "../components/backgrounds/Dot";
import Footer from "../components/home/Footer";

const Privacy = () => {
  return (
    <>
      <div className="w-full min-h-screen flex bg-white pt-[9vh] p-6 flex-col relative gap-2">
        <Dot />
        <h1
          data-aos="fade-down"
          className="w-full text-center text-3xl font-bold text-primary mt-20"
        >
          Privacy Policy
        </h1>
        <p data-aos="fade-up" className="text-xs text-primary">
          This Privacy Policy describes how Upthrive Public Relations and
          Advertising Company ("Upthrive," "we," "us," or "our") collects, uses,
          and discloses your personal information when you use our services or
          interact with our website.
        </p>
        <h2
          data-aos="fade-right"
          className="w-full text-center text-lg font-bold text-secondary mt-2"
        >
          Information We Collect
        </h2>
        <ul className="list-inside list-disc text-xs text-primary leading-6">
          <li>
            <span className="text-secondary">Personal Information:</span> When
            you use our services or interact with our website, we may collect
            personal information such as your name, email address, phone number,
            and company details.
          </li>
          <li>
            <span className="text-secondary">Usage Information:</span> We may
            also collect information about how you use our website, including
            your IP address, browser type, pages visited, and other usage data.
          </li>
        </ul>
        <h2
          data-aos="fade-right"
          className="w-full text-center text-lg font-bold text-secondary mt-2"
        >
          How We Use Your Information
        </h2>
        <p className="text-xs text-primary">
          We may use the information we collect for the following purposes:
        </p>
        <ul className="list-inside list-disc text-xs text-primary leading-6">
          <li>To provide and maintain our services</li>
          <li>To communicate with you about our services and promotions</li>
          <li>To analyze usage of our website and improve our services</li>
          <li>To comply with legal and regulatory requirements</li>
        </ul>
        <h2
          data-aos="fade-right"
          className="w-full text-left text-lg font-bold text-secondary mt-2"
        >
          Information Sharing
        </h2>
        <p className="text-xs text-primary">
          We may share your personal information with third-party service
          providers who assist us in providing our services, such as payment
          processors and marketing partners. We may also disclose your
          information in response to a legal request or to protect our rights or
          property.
        </p>
        <h2
          data-aos="fade-right"
          className="w-full text-left text-lg font-bold text-secondary mt-2"
        >
          Cookies and Tracking Technologies
        </h2>
        <p className="text-xs text-primary">
          We use cookies and similar tracking technologies to analyze usage of
          our website and improve our services. You can control cookies through
          your browser settings, but please note that disabling cookies may
          affect your experience on our website.
        </p>
        <h2
          data-aos="fade-right"
          className="w-full text-left text-lg font-bold text-secondary mt-2"
        >
          Data Security
        </h2>
        <p className="text-xs text-primary">
          We take reasonable measures to protect your personal information from
          unauthorized access, use, or disclosure. However, please be aware that
          no method of transmission over the internet or electronic storage is
          100% secure.
        </p>
        <h2
          data-aos="fade-right"
          className="w-full text-left text-lg font-bold text-secondary mt-2"
        >
          Your Choices
        </h2>
        <p className="text-xs text-primary">
          You may choose not to provide certain personal information, but this
          may limit your ability to use our services or access certain features
          of our website.
        </p>
        <h2
          data-aos="fade-right"
          className="w-full text-left text-lg font-bold text-secondary mt-2"
        >
          Changes to this Privacy Policy
        </h2>
        <p className="text-xs text-primary">
          We may update this Privacy Policy from time to time, and any changes
          will be posted on this page. We encourage you to review this Privacy
          Policy periodically for any updates.
        </p>
        <h2
          data-aos="fade-right"
          className="w-full text-left text-lg font-bold text-secondary mt-2"
        >
          Contact Us
        </h2>
        <p className="text-xs text-primary">
          If you have any questions about this Privacy Policy, please contact us
          at <span className="text-secondary">support@uprac.biz</span>.
        </p>
      </div>
      <Footer />
    </>
  );
};

export default Privacy;
