import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import HomeLayout from "./layout/HomeLayout";
import Home from "./pages/Home";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import AOS from "aos";
import "aos/dist/aos.css";
import Jobs from "./pages/Jobs";
import Apply from "./pages/Apply";
import Applications from "./pages/Applications";
import SingleApplication from "./pages/SingleApplication";

const App = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
    // eslint-disable-next-line
  }, []);

  return (
    <Routes>
      <Route
        path="/"
        element={
          <HomeLayout>
            <Home />
          </HomeLayout>
        }
      />
      <Route
        path="/privacy-policy"
        element={
          <HomeLayout>
            <Privacy />
          </HomeLayout>
        }
      />
      <Route
        path="/terms-and-conditions"
        element={
          <HomeLayout>
            <Terms />
          </HomeLayout>
        }
      />
      <Route
        path="/job-offers"
        element={
          <HomeLayout>
            <Jobs />
          </HomeLayout>
        }
      />
      <Route
        path="/job-offers/:role"
        element={
          <HomeLayout>
            <Apply />
          </HomeLayout>
        }
      />
      <Route
        path="/restricted-access/admin/applications"
        element={
          <HomeLayout>
            <Applications />
          </HomeLayout>
        }
      />
      <Route
        path="/restricted-access/admin/applications/:id"
        element={
          <HomeLayout>
            <SingleApplication />
          </HomeLayout>
        }
      />
    </Routes>
  );
};

export default App;
