import React from "react";
import Logo from "../logos/Uprac.png";
import { Link } from "react-router-dom";

const item = [
  { name: "Home", link: "/" },
  { name: "Terms & Condition", link: "/terms-and-conditions" },
  { name: "Privacy Policy", link: "/privacy-policy" },
];

const Footer = () => {
  return (
    <footer className="w-full p-4 bg-black text-secondary flex flex-col">
      <div className="w-full grid grid-cols-2 lg:grid-cols-4 pb-2 border-b border-primary gap-2">
        <div className="w-full flex flex-col gap-2 col-span-2">
          <Link>
            <img src={Logo} alt="" className="w-16" />
          </Link>
          <h5 className="text-sm">
            Upthrive Public Relations and Advertising Company
          </h5>
          <p className="text-xs text-primary">
            Where Your Success is Our Priority
          </p>
        </div>
        <div className="w-full flex flex-col gap-2">
          <h5 className="text-lg font-semibold">Useful Links</h5>
          {item.map((item) => {
            return (
              <Link
                to={item.link}
                className="flex items-center gap-2 text-xs hover:translate-x-2 transition-all ease-in-out"
              >
                <i className="fa-solid fa-caret-right text-primary"></i>
                <span>{item.name}</span>
              </Link>
            );
          })}
        </div>
        <div className="w-full flex flex-col gap-2">
          <h5 className="text-lg font-semibold">Contact Information</h5>
          <div className="flex items-center gap-2 text-xs hover:translate-x-2 transition-all ease-in-out">
            <i className="fa-solid fa-phone text-primary"></i>
            <span>+1 (405) 369 6126</span>
          </div>
          <div className="flex items-center gap-2 text-xs hover:translate-x-2 transition-all ease-in-out">
            <i className="fa-solid fa-location-dot text-primary"></i>
            <span>827 Adonis Ave. Henderson NV 89074</span>
          </div>
          <div className="flex items-center gap-2 text-xs hover:translate-x-2 transition-all ease-in-out">
            <i className="fa-solid fa-envelope text-primary"></i>
            <span>support@uprac.biz</span>
          </div>
        </div>
      </div>
      <div className="flex justify-center gap-8 items-center py-2">
        <Link>
          <i className="fa-brands fa-facebook"></i>
        </Link>
        <Link>
          <i className="fa-brands fa-linkedin"></i>
        </Link>
      </div>
      <span className="w-full text-center text-xss">
        © 2022 Uprac. All rights
        reserved
      </span>
    </footer>
  );
};

export default Footer;
