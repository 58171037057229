const Role = [
  {
    title: "Public Relations Manager",
    responsibilities: [
      "Develop and implement strategic PR campaigns to enhance clients' brand visibility and reputation.",
      "Cultivate and maintain relationships with media outlets and industry influencers.",
      "Create compelling press releases, pitches, and other PR materials.",
      "Monitor and analyze PR metrics to measure the effectiveness of campaigns.",
    ],
    requirements: [
      "GED.",
      "Proven experience in PR, preferably in an agency setting.",
      "Excellent written and verbal communication skills.",
      "Strong interpersonal and networking abilities.",
      "Proficiency in media monitoring and analytics tools.",
    ],
  },
  {
    title: "Advertising Account Executive",
    responsibilities: [
      "Manage client accounts and develop advertising strategies to meet their objectives.",
      "Coordinate with creative teams to develop ad campaigns across various channels.",
      "Monitor campaign performance and provide regular updates to clients.",
      "Build and maintain relationships with clients to ensure their satisfaction and retention.",
    ],
    requirements: [
      "GED.",
      "Previous experience in advertising or account management.",
      "Strong project management and organizational skills.",
      "Excellent communication and presentation abilities.",
      "Familiarity with advertising platforms and tools.",
    ],
  },
  {
    title: "Digital Marketing Specialist",
    responsibilities: [
      "Develop and execute digital marketing campaigns across multiple channels, including social media, email, and PPC.",
      "Analyze campaign performance and optimize strategies to improve results.",
      "Create engaging content for digital platforms, including blog posts, social media posts, and email newsletters.",
      "Stay updated on digital marketing trends and best practices.",
    ],
    requirements: [
      "GED.",
      "Proven experience in digital marketing, with a focus on social media and PPC campaigns.",
      "Strong analytical skills and proficiency in data analysis tools.",
      "Creative thinking and ability to generate innovative marketing ideas.",
      "Familiarity with SEO, SEM, and Google Analytics.",
    ],
  },
];

export default Role;
